import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import logo from "./assets/images/Gunaas_Logo_Icon.svg";

// const ComingSoonPage = React.lazy(() => import("./Pages/ComingSoonPage"));
const UnderConstructionPage = React.lazy(() =>
  import("./Pages/UnderConstructionPage")
);
const LandingPage = React.lazy(() => import("./Pages/LandingPage"));
const StoryPage = React.lazy(() => import("./Pages/StoryPage"));
// const GiftsPage = React.lazy(() => import("./Pages/GiftsPage"));
const HighJewelleryPage = React.lazy(() => import("./Pages/HighJewelleryPage"));
const EngagementAndWeddingPage = React.lazy(() => import("./Pages/EngagementAndWeddingPage"));
const JewelleryPage = React.lazy(() => import("./Pages/JewelleryPage"));
// const MusingNaturePage = React.lazy(() => import("./Pages/MusingNaturePage"));
// const KnowYourDiamondPage1 = React.lazy(() => import("./Pages/KnowYourDiamondPage1"));
// const KnowYourDiamondPage2 = React.lazy(() => import("./Pages/KnowYourDiamondPage2"));
// const CreationPage = React.lazy(() => import("./Pages/CreationPage"));
// const LoungePage = React.lazy(() => import("./Pages/LoungePage"));
const loading = () => (<div className="animated fadeIn pt-3 text-center loading-message">    <img src={logo} alt="" />
</div>
);

function App(props) {
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          {/* <Route exact path="/lounge" name="Lounge" render={props => <LoungePage {...props} />} />
          <Route exact path="/creations" name="Story" render={props => <CreationPage {...props} />} />
          <Route exact path="/kyd1" name="KnowYourDiamondPage1" render={props => <KnowYourDiamondPage1 {...props} />} />
          <Route exact path="/kyd2" name="KnowYourDiamondPage2" render={props => <KnowYourDiamondPage2 {...props} />} />
          <Route exact path="/musingnature" name="MusingNaturePage" render={props => <MusingNaturePage {...props} />} />
          <Route exact path="/gifts" name="gifts" render={props => <GiftsPage {...props} />} /> */}
          {/* <Route exact path="/" name="ComingSoon" render={props => <ComingSoonPage {...props}/>} />  */}

          <Route exact path="/jewellery" name="JewelleryPage" render={props => <JewelleryPage {...props} />} />
          <Route exact path="/enw" name="EngagementAndWedding" render={props => <EngagementAndWeddingPage {...props} />} />
          <Route exact path="/highjewellery" name="highjewellery" render={props => <HighJewelleryPage {...props} />} />
          <Route exact path="/story" name="Story" render={props => <StoryPage {...props} />} />
          <Route exact path="/" name="Home" render={props => <LandingPage {...props} />} />
          <Route exact path="/(musingnature|kyd1|kyd2|gifts|lounge|creations|terms|privacy|)/" name="UnderConstruction" render={props => <UnderConstructionPage {...props} />} />

        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
